import React, { FC } from 'react';

import { range } from 'lodash';
import Select from 'react-select';
import { 
    MainDiv, 
    PageItem, 
    PagesWrapper, 
    SizeWrapper, 
} from './Pagination.styles';

interface IProps {
    withSize?: boolean;
    limit?: number;
    setLimit?: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    pages: number;
    setPage: React.Dispatch<React.SetStateAction<number>>; 
    setSearchTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
}

const options = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ]

export const Pagination: FC<IProps> = ({
    withSize,
    limit,
    setLimit,
    page,
    setPage,
    pages,
    setSearchTrigger,
    isLoading,
}) => {

    const firstItem = Math.floor((page - 1) / 10) * 10;
    let hasPrevious = firstItem > 0;
    let hasPreviousUltra = page - 100 > 0;
    let hasNext = firstItem < (Math.floor(pages / 10) * 10);
    let hasNextUltra = page + 100 <= pages;
    const pagesTotal = pages;

    return (<MainDiv>
        <PagesWrapper withSize={withSize}>
        {
            hasPreviousUltra && (<PageItem onClick={() => {
                setPage(page - 100);
                setSearchTrigger((prevState: boolean) => !prevState)
            }}>[-100]</PageItem>)
        }
        {
            hasPrevious && (<PageItem paddingRight={true} onClick={() => {
                setPage(page - 10);
                setSearchTrigger((prevState: boolean) => !prevState)
            }}>[-10]</PageItem>)
        }
        {
            range(firstItem + 1, hasNext ? firstItem + 11 : pagesTotal + 1).map((idx: number) => (<PageItem 
                isSelected={idx === page}
                onClick={() => {
                    if (isLoading) {
                        return;
                    }
                    setPage(idx);
                    setSearchTrigger((prevState: boolean) => !prevState);
                }}>{ idx }</PageItem>))
        }
        {
            hasNext && (<PageItem paddingLeft={true} onClick={() => {
                setPage(page + 10);
                setSearchTrigger((prevState: boolean) => !prevState);
            }}>[+10]</PageItem>)
        }
        {
            hasNextUltra && (<PageItem onClick={() => {
                setPage(page + 100);
                setSearchTrigger((prevState: boolean) => !prevState)
            }}>[+100]</PageItem>)
        }
        </PagesWrapper>
        {
            withSize && (<SizeWrapper>
                <label>Page size: </label>
                <Select 
                    options={options}
                    defaultValue={options.find(item => item.value === limit)}
                    onChange={(item) => {
                        setLimit && setLimit(item?.value || 100);
                        setSearchTrigger((prevState: boolean) => !prevState);
                    }} />
            </SizeWrapper>)
        }
    </MainDiv>);
}